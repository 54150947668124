import { h, Component, render } from "preact";
import tmdbIcon from "../../../images/tmdb.svg";
const Attribution = () => {
  return (
    <div className="flex gap-1 max-h-14 items-end">
      <div className="w-20">
        <a href="https://www.themoviedb.org/" target="_blank">
          <img
            className="imdb-logo"
            src={tmdbIcon}
            alt="Icon for The Movie Database"
          />
        </a>
      </div>
      <div className="m-2 visible-text text-white">
        Movies &amp; series data list are taken from{" "}
        <a href="https://www.themoviedb.org/" target="_blank">
          TMDB
        </a>
        .<br />
        Background photo taken from&nbsp;
        <a
          href="https://unsplash.com/jakehills?utm_source=pezo&amp;utm_medium=referral"
          target="_blank"
        >
          Unsplash
        </a>{" "}
        by&nbsp;
        <a
          href={`https://unsplash.com/jakehills?utm_source=pezo&utm_medium=referral`}
          target="_blank"
        >
          <i>Jake Hills</i>
        </a>
      </div>
    </div>
  );
};

export default Attribution;
