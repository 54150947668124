import { h, Component, render } from "preact";

const TrendsFallback = () => {
  return (
    <article class="max-w-full min-h-150px grid place-items-center text-center text-primary-lightest">
      Oops! Facing Issues loading trending movies.
    </article>
  );
};

export default TrendsFallback;
