import { h, Fragment, Component, render } from "preact";
import { useState } from "preact/hooks";
import { Switch } from "@headlessui/react";

function Toggle({ enabled, toggleAction }) {
  return (
    <Switch
      checked={enabled}
      onChange={toggleAction}
      className={`${
        enabled ? "bg-green-600" : "bg-gray-200"
      } relative inline-flex items-center h-6 rounded-full w-11 outline-none`}
    >
      <span className="sr-only"></span>
      <span
        className={`${
          enabled ? "translate-x-6" : "translate-x-1"
        } inline-block w-4 h-4 transform bg-white rounded-full transition-all`}
      />
    </Switch>
  );
}

export default Toggle;
