import { h, Fragment, Component, render } from "preact";
import { CURRENT_SEARCH_TYPE, searchTypes } from "../../../helpers/constant";
import { useLocalStorageState } from "../../customHooks/useLocalStorageState";
import { useRef } from "preact/hooks";
import SearchIcon from "../../components/Icons/SearchIcon";
const Search = ({ currentSearchEngine, setCurrentSearchEngine }) => {
  const [searchType, setSearchType] = useLocalStorageState(
    CURRENT_SEARCH_TYPE,
    "link"
  );
  const searchInput = useRef("");
  const searchQuery = (e) => {
    e.preventDefault();
    let query = searchInput.current.value;
    if (query) {
      let redirectLink = currentSearchEngine[searchType] + query;
      window.open(redirectLink, "_self");
    }
  };

  return (
    <form
      className="flex flex-col gap-2 w-full max-w-2xl px-10"
      onSubmit={searchQuery}
    >
      <div>
        <ul className="flex space-between gap-4 pl-8 cursor-pointer text-white text-base tracking-wide visible-text">
          {searchTypes.map((type) => (
            <li
              key={type.value}
              onClick={() => setSearchType(type.value)}
              className={`search-type ${
                type.value === searchType && "search-type--active"
              }`}
            >
              {type.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex bg-white p-1 pl-2 pr-2 rounded-full fully-flex">
        <div className="w-6 h-6 fully-flex">
        </div>
        <input
          id="query"
          name="query"
          className="flex-1 px-2 outline-none text-base"
          ref={searchInput}
        />
        <div className="w-6 h-6 cursor-pointer" onClick={searchQuery}>
          <SearchIcon className="max-w-full h-auto hover:text-gray-900 text-gray-500" />
        </div>
      </div>
    </form>
  );
};

export default Search;
