import { h, Component, render, Fragment } from "preact";
import { Media } from "../../../services/Media";
import { useEffect, useState } from "preact/hooks";
import { useAsync } from "../../customHooks/useAsync";
import TrendsList from "../Trends/TrendsList";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import TrendsFallback from "./TrendsFallback";
const Trends = ({ currentTrendingSettings, openMediaModal }) => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const fetchedData = data?.data || {};
  const [trends, setTrends] = useState(() => []);
  const currentPage = fetchedData?.page || 1;
  const maxPage = fetchedData?.total_pages || 1;

  useEffect(() => {
    setTrends([]);
    run(
      Media.fetchTrending({
        time: currentTrendingSettings.time,
        type: currentTrendingSettings.type,
      })
    );
  }, [currentTrendingSettings.time, currentTrendingSettings.type]);
  useEffect(() => {
    if (fetchedData?.results) {
      setTrends((trends) => trends.concat(fetchedData?.results));
    }
  }, [data]);

  const loadMore = () => {
    if (currentPage < maxPage) {
      run(
        Media.fetchTrending({
          time: currentTrendingSettings.time,
          type: currentTrendingSettings.type,
          page: currentPage + 1,
        })
      );
    }
  };

  return (
    <ErrorBoundary key={data} FallbackComponent={TrendsFallback}>
      <TrendsList
        status={status}
        trends={trends}
        loadMore={loadMore}
        currentPage={currentPage}
        maxPage={maxPage}
        mediaType={currentTrendingSettings.type}
        openMediaModal={openMediaModal}
      />
    </ErrorBoundary>
  );
};

export default Trends;
