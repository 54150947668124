import { h, Component, render } from "preact";

const BookmarkIcon = ({ ...style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...style}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
    />
  </svg>
);

export default BookmarkIcon;
