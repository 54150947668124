import { h, Component, render } from "preact";

const RadioButton = ({ id, name, label, checked, handleChange }) => {
  return (
    <div className="flex">
      <input
        type="radio"
        id={id}
        name={name}
        className="hidden"
        checked={checked}
        onChange={handleChange}
      />
      <label for={id} className="flex items-center cursor-pointer text-base">
        <span className="w-4 h-4 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
