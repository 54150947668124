import { h, Component, render } from "preact";
import StyledCard from "../StyledCard/StyledCard";
import { useEffect, useRef } from "preact/hooks";
const TvCard = ({ openMediaModal, tvDetail }) => {
  const tvRef = useRef(null);
  useEffect(() => {
    if (tvDetail?.backdrop_path) {
      tvRef.current.style.backgroundImage = `url('https://image.tmdb.org/t/p/w300/${tvDetail.backdrop_path}')`;
      tvRef.current.style.backgroundPosition = "center center";
    }
  }, []);
  return (
    <StyledCard
      onClick={() => openMediaModal(tvDetail.id)}
      className="max-w-280 cursor-pointer"
    >
      <div
        ref={tvRef}
        className="rounded-md text-white grid place-items-center p-2 max-h-40 h-40 overflow-hidden"
      >
        <h4 className="font-bold text-center visible-text text-2xl">
          {tvDetail.name}
        </h4>
      </div>
    </StyledCard>
  );
};

export default TvCard;
