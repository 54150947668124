import { h, Component, render } from "preact";

const LoadingSpinner = () => (
  <div className="max-w-full grid place-items-center">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
);

export default LoadingSpinner;
