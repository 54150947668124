import { h, Component, render } from "preact";

const MediaFallback = () => {
  return (
    <article class="max-w-full bg-white min-h-150px grid place-items-center text-center text-primary-lightest">
      Oops! Facing Issues loading media.
    </article>
  );
};

export default MediaFallback;
