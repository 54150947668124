import { h, Component, render, Fragment } from "preact";
import Modal from "../Modal/Modal";
import { TYPE_MOVIE, TYPE_TV } from "../../../helpers/constant";
import MovieDetail from "../MovieDetail/MovieDetail";
import TvDetail from "../TvDetail/TvDetail";
import { useAsync } from "../../customHooks/useAsync";
import { useEffect } from "preact/hooks";
import { Media } from "../../../services/Media";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import MediaFallback from "./MediaFallback";
const MediaDetails = ({ isOpen, handleClose, showID, mediaType }) => {
  const { data, status, error, run } = useAsync({
    status: showID ? "pending" : "idle",
  });
  const mediaInfo = data?.data || {};
  useEffect(() => {
    if (showID) {
      mediaType === TYPE_MOVIE
        ? run(Media.fetchMovieDetail({ movieID: showID }))
        : run(Media.fetchTvDetail({ tvID: showID }));
    }
  }, [showID]);
  if (!showID) return <div></div>;

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Movie Details">
      <ErrorBoundary key={mediaInfo} FallbackComponent={MediaFallback}>
        {mediaType === TYPE_MOVIE ? (
          <MovieDetail status={status} error={error} movieInfo={mediaInfo} />
        ) : (
          <TvDetail status={status} error={error} tvInfo={mediaInfo} />
        )}
      </ErrorBoundary>
    </Modal>
  );
};

export default MediaDetails;
