import Http from "axios";
const API_URL = "https://api.piezo.run/api/";

class Api {
  constructor() {
    Http.defaults.baseURL = API_URL;
    Http.defaults.timeout = 300000;
  }

  async get(resource, responseType = null, params = {}) {
    const config = {
      responseType,
      params,
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await Http.get(`${API_URL}${resource}`, config);

      return res.data;
    } catch (error) {
      return error.response;
    }
  }
}
export default new Api();
