import { h, Component, render } from "preact";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import StyledCard from "../../components/StyledCard/StyledCard";
import MovieCard from "../MovieCard/MovieCard";
import TvCard from "../TvCard/TvCard";
import { TYPE_MOVIE } from "../../../helpers/constant";
const TrendsLists = ({
  status,
  trends,
  loadMore,
  currentPage,
  maxPage,
  mediaType,
  openMediaModal,
}) => {
  if (status === "idle") {
    return (
      <StyledCard>
        <div className="text-center text-white">Select a Trending Option</div>
      </StyledCard>
    );
  } else if (status === "rejected") {
    //this will be handled by error boundary
    throw error;
  } else if (status === "resolved" || status === "pending") {
    return (
      <div className="flow-content flex flex-col items-center">
        {trends.map((trend) => {
          return mediaType === TYPE_MOVIE ? (
            <MovieCard
              key={trend.id}
              movieDetail={trend}
              openMediaModal={openMediaModal}
            />
          ) : (
            <TvCard
              key={trend.id}
              tvDetail={trend}
              openMediaModal={openMediaModal}
            />
          );
        })}
        <StyledCard>
          {status === "pending" ? (
            <LoadingSpinner />
          ) : (
            <div
              onClick={loadMore}
              className="text-center visible-text text-white font-bold cursor-pointer"
            >
              {currentPage === maxPage ? "No More" : "Load More"}
            </div>
          )}
        </StyledCard>
      </div>
    );
  }
  throw new Error("This isn't possible!");
};

export default TrendsLists;
