import { h, Component, render, Fragment } from "preact";
import { useRef } from "preact/hooks";
import { Dialog, Transition } from "@headlessui/react";

const Modal = ({ title, isOpen, handleClose, children }) => {
  let completeButtonRef = useRef(null);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child as={Fragment}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-max p-6 my-8 overflow-hidden  align-middle transition-all transform bg-transparent">
              <Dialog.Title as="h3" className="hidden" ref={completeButtonRef}>
                {title}
              </Dialog.Title>
              {children}
            </div>
          </Transition.Child>
        </div>
        <button className="h-0 w-0 overflow-hidden" ref={completeButtonRef} />
      </Dialog>
    </Transition>
  );
};

export default Modal;
