import { h, Component, render, Fragment } from "preact";
import { DAY, WEEK } from "../../../helpers/constant";
import Modal from "../Modal/Modal";
import Toggle from "../Toggle/Toggle";
import RadioButton from "../RadioButton/RadioButton";
const Settings = ({
  isOpen,
  handleClose,
  currentTrendingSettings,
  setCurrentTrendingSettings,
}) => {
  function toggleShowTrending() {
    setCurrentTrendingSettings((currentTrendingSettings) => ({
      ...currentTrendingSettings,
      show: !currentTrendingSettings.show,
    }));
  }

  function toggleTrendingTime(time) {
    setCurrentTrendingSettings((currentTrendingSettings) => ({
      ...currentTrendingSettings,
      time,
    }));
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Settings">
      <div className="flex flex-col gap-4 p-6  bg-opacity-50 visible-text shadow-md min-w-300 max-w-4xl bg-black text-white rounded-lg text-lg ">
        <h3 class="text-left uppercase mb-0 text-xl">Settings</h3>
        <hr />
        <div>
          <h4 className="text-left text-lg">Trending List</h4>
          <li className="flex justify-between p-2">
            <h4 className="text-base">Show</h4>
            <span>
              <Toggle
                enabled={currentTrendingSettings.show}
                toggleAction={toggleShowTrending}
              />
            </span>
          </li>
          <li className="flex flex-col">
            <h4 class="text-left">Fetch Trending for</h4>
            <ul class="flex gap-1 pt-3 justify-evenly">
              <li>
                <RadioButton
                  id="tr-day"
                  name="trending"
                  label="Day"
                  checked={currentTrendingSettings.time === DAY}
                  handleChange={() => toggleTrendingTime(DAY)}
                />
              </li>
              <li>
                <RadioButton
                  id="tr-week"
                  name="trending"
                  label="Week"
                  checked={currentTrendingSettings.time === WEEK}
                  handleChange={() => toggleTrendingTime(WEEK)}
                />
              </li>
            </ul>
          </li>
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
