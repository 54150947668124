import { h, Fragment, Component, render } from "preact";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import StyledCard from "../../components/StyledCard/StyledCard";
import MovieCard from "../MovieCard/MovieCard";
import TvCard from "../TvCard/TvCard";
import { TYPE_MOVIE } from "../../../helpers/constant";
const SearchList = ({
  status,
  results,
  loadMore,
  currentPage,
  maxPage,
  mediaType,
  openMediaModal,
}) => {
  if (status === "idle") {
    return <></>;
  } else if (status === "rejected") {
    //this will be handled by error boundary
    throw error;
  } else if (status === "resolved" || status === "pending") {
    if (status === "resolved" && results.length === 0) {
      return <p className="m-auto text-white max-w-max visible-text"></p>;
    }
    return (
      <div className="flow-content">
        <div className="flex gap-2 flex-wrap justify-center">
          {results.map((result) => {
            return mediaType === TYPE_MOVIE ? (
              <MovieCard
                key={result.id}
                movieDetail={result}
                openMediaModal={openMediaModal}
              />
            ) : (
              <TvCard
                key={result.id}
                tvDetail={result}
                openMediaModal={openMediaModal}
              />
            );
          })}
        </div>
        <StyledCard className="m-auto max-w-max">
          {status === "pending" ? (
            <LoadingSpinner />
          ) : (
            <div
              onClick={loadMore}
              className="cursor-pointer text-center p-5 w-full visible-text text-white font-bold"
            >
              {currentPage === maxPage ? "No More" : "Load More"}
            </div>
          )}
        </StyledCard>
      </div>
    );
  }
  throw new Error("This isn't possible!");
};

export default SearchList;
