import { h, Component, render } from "preact";

import { useState } from "preact/hooks";

const Tooltip = (props) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className={`Tooltip-Wrapper`}
      // When to show the tooltip
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={` Tooltip-Tip ${props.direction || "top"}`}>
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
