import { h, Component, render } from "preact";

const SearchFallback = () => {
  return (
    <article class="max-w-full min-h-150px grid place-items-center text-center text-white">
      Oops! Facing Issues searching media.
    </article>
  );
};

export default SearchFallback;
