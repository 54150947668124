import Api from "../api";

class Media {
  fetchTrending = async ({ type, time, page = 1 }) => {
    return Api.get(`trending?type=${type}&time=${time}&page=${page}`);
  };

  fetchMovieDetail = async ({ movieID }) => {
    return Api.get(`movie?movie=${movieID}`);
  };

  fetchTvDetail = async ({ tvID }) => {
    return Api.get(`tv?tv=${tvID}`);
  };

  searchMovie = async ({ q, page = 1 }) => {
    return Api.get(`search/movie?q=${q}&page=${page}`);
  };

  searchTv = async ({ q, page = 1 }) => {
    return Api.get(`search/tv?q=${q}&page=${page}`);
  };
}

export default new Media();
