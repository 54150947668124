import { h, Component, render } from "preact";

const StyledCard = ({ className, children, onClick }) => {
  return (
    <div onClick={onClick} className={`styled-card ${className}`}>
      {children}
    </div>
  );
};

export default StyledCard;
