import { h, Component, render } from "preact";

const HistoryIcon = ({ ...style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...style}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export default HistoryIcon;
