import BingLogo from "../images/bing.png";
import DefaultBackground from "../images/default-bg.jpeg";

export const CURRENT_SEARCH_ENGINE = "CURRENT_SEARCH_ENGINE";

export const searchList = [
  {
    name: "Bing",
    link: "https://www.bing.com/search?q=",
    imagesLink: "https://www.bing.com/images/search?q=",
    videosLink: "https://www.bing.com/videos/search?q=",
    newsLink: "https://www.bing.com/news/search?q=",
    icon: BingLogo,
  },
];

export const CURRENT_SEARCH_TYPE = "CURRENT_SEARCH_TYPE";

export const searchTypes = [
  {
    name: "All",
    value: "link",
  },
  {
    name: "Images",
    value: "imagesLink",
  },
  {
    name: "Videos",
    value: "videosLink",
  },
  {
    name: "News",
    value: "newsLink",
  },
];
export const BACKGROUND = "BACKGROUND";
export const DEFAULT_BACKGROUND = DefaultBackground;

export const TYPE_MOVIE = "movie";
export const TYPE_TV = "tv";
export const DAY = "day";
export const WEEK = "week";

export const TRENDING_SETTINGS = "TRENDING_SETTINGS";
export const DEFAULT_TRENDING_SETTINGS = {
  type: TYPE_MOVIE,
  time: WEEK,
  show: true,
};
