import { h, Component, render } from "preact";
import { useState, useEffect } from "preact/hooks";
import moment from "moment";

const Timer = () => {
  const [time, setTime] = useState(moment().format("HH:mm:ss"));
  const tick = () => setTime(moment().format("HH:mm:ss"));
  useEffect(() => {
    const intervalID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <h3 class="visible-text text-white text-5xl font-light">{time}</h3>
      <h3 class="visible-text text-white text-2xl font-extralight">
        {moment().format("MMM DD, YYYY")}
      </h3>
    </div>
  );
};

export default Timer;
