import { h, Component, render } from "preact";
import Logo from "../../../images/piezo-run.png";
const AppLogo = () => {
  return (
    <div className="w-24 h-16 fully-flex">
      <img src={Logo} className="img-logo" />
    </div>
  );
};

export default AppLogo;
