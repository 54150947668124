import { h, render, Component, Fragment } from "preact";
import {
  CURRENT_SEARCH_ENGINE,
  searchList,
  DEFAULT_BACKGROUND,
  BACKGROUND,
  TYPE_MOVIE,
  TYPE_TV,
  TRENDING_SETTINGS,
  DEFAULT_TRENDING_SETTINGS,
  WEEK,
} from "../helpers/constant";
import { useLocalStorageState } from "./customHooks/useLocalStorageState";
import Search from "./components/Search/Search";
import Timer from "./components/Timer/Timer";
import { useState, useEffect } from "preact/hooks";
import Trends from "./components/Trends/Trends";
import StyledCard from "./components/StyledCard/StyledCard";
import SearchMedia from "./components/SearchMedia/SearchMedia";
import MovieIcon from "./components/Icons/MovieIcon";
import TvIcon from "./components/Icons/TvIcon";
import BookmarkIcon from "./components/Icons/BookmarkIcon";
import DownloadIcon from "./components/Icons/DownloadIcon";
import HistoryIcon from "./components/Icons/HistoryIcon";
import Tooltip from "./components/Tooltip/Tooltip";
import MediaDetails from "./components/MediaDetails/MediaDetails";
import GearIcon from "./components/Icons/GearIcon";
import Settings from "./components/Settings/Settings";
import Attribution from "./components/Attribution/Attribution";
import AppLogo from "./components/AppLogo/AppLogo";
const App = () => {
  const [currentSearchEngine, setCurrentSearchEngine] = useLocalStorageState(
    CURRENT_SEARCH_ENGINE,
    searchList[0]
  );
  const [currentBackground, setCurrentBackground] = useLocalStorageState(
    BACKGROUND,
    DEFAULT_BACKGROUND
  );
  const [currentTrendingSettings, setCurrentTrendingSettings] =
    useLocalStorageState(TRENDING_SETTINGS, DEFAULT_TRENDING_SETTINGS);
  useEffect(() => {
    document.body.style.background = `url('${currentBackground}')`;
    document.body.style.backgroundSize = "cover";
  }, [currentBackground]);
  const [mediaModal, setMediaModal] = useState({
    id: null,
    show: false,
  });
  const [settingsModal, setSettingsModal] = useState(false);
  function openMediaModal(mediaID) {
    setMediaModal({
      id: mediaID,
      show: true,
    });
  }

  function closeMediaModal(mediaID) {
    setMediaModal({
      id: null,
      show: false,
    });
  }

  function changeCurrentTrendingSettingsType(type) {
    setCurrentTrendingSettings({
      ...currentTrendingSettings,
      type,
    });
  }

  function openBookmarks() {
    chrome.tabs.update({ url: "chrome://bookmarks/" });
  }

  function openHistory() {
    chrome.tabs.update({ url: "chrome://history/" });
  }

  function openDownloads() {
    chrome.tabs.update({ url: "chrome://downloads" });
  }

  return (
    <>
      <main className="flex flex-col padding-please h-screen gap-1 max-w-95p ">
        <section className="top-section">
          <Timer />
          <Search
            currentSearchEngine={currentSearchEngine}
            setCurrentSearchEngine={setCurrentSearchEngine}
          />
        </section>
        <section className="media-section h-perso flex gap-2">
          <div className="trend-list custom-scroll flex flex-col gap-1 pb-7 pr-2 overflow-auto">
            {currentTrendingSettings.show && (
              <>
                <StyledCard>
                  <h3 className="visible-text rounded-md text-white font-bold p-2 text-center">
                    Trending{" "}
                    {currentTrendingSettings.type === TYPE_MOVIE
                      ? "Movies"
                      : "TV Shows"}
                    {currentTrendingSettings.time === WEEK
                      ? " -This Week"
                      : " -Today"}
                  </h3>
                </StyledCard>
                <Trends
                  currentTrendingSettings={currentTrendingSettings}
                  openMediaModal={openMediaModal}
                />
              </>
            )}
          </div>

          <div className="flex">
            <SearchMedia
              mediaType={currentTrendingSettings.type}
              openMediaModal={openMediaModal}
            />
          </div>
        </section>
        {/* Left Hand Menu for Movie/Tv Switch*/}
        <ul className="side-menu w-max absolute left-6 top-44 flow-content--lg ">
          <li
            onClick={() => changeCurrentTrendingSettingsType(TYPE_MOVIE)}
            class={`w-7 h-7 text-white grid place-items-center cursor-pointer ${
              currentTrendingSettings.type === TYPE_MOVIE && "active"
            }`}
          >
            <div className="w-5 h-5">
              <Tooltip content="Switch to movies" direction="right">
                <MovieIcon className="max-w-full w-full h-auto" />
              </Tooltip>
            </div>
          </li>
          <li
            onClick={() => changeCurrentTrendingSettingsType(TYPE_TV)}
            class={`w-7 h-7 text-white grid place-items-center cursor-pointer ${
              currentTrendingSettings.type === TYPE_TV && "active"
            }`}
          >
            <div className="w-5 h-5">
              <Tooltip content="Switch to TV Shows" direction="right">
                <TvIcon className="max-w-full w-full h-auto" />
              </Tooltip>
            </div>
          </li>
        </ul>
        {/* Right hand menu for bookmars, history and downloads*/}
        <ul className="side-menu w-max absolute right-6 top-96 flow-content--lg ">
          {/* <li
            onClick={openBookmarks}
            className="w-7 h-7 grid place-items-center cursor-pointer text-white"
          >
            <div className="w-5 h-5">
              <Tooltip content="Bookmarks" direction="left">
                <BookmarkIcon className="max-w-full w-full h-auto" />
              </Tooltip>
            </div>
          </li>
          <li
            onClick={openHistory}
            className="w-7 h-7 grid place-items-center cursor-pointer text-white"
          >
            <div className="w-5 h-5">
              <Tooltip content="History" direction="left">
                <HistoryIcon className="max-w-full w-full h-auto " />
              </Tooltip>
            </div>
          </li>
          <li
            onClick={openDownloads}
            className="w-7 h-7 grid place-items-center cursor-pointer text-white"
          >
            <div className="w-5 h-5">
              <Tooltip content="Downloads" direction="left">
                <DownloadIcon className="max-w-full w-full h-auto" />
              </Tooltip>
            </div>
          </li> */}
        </ul>
        {/* Bottom right Settings Menu */}
        <ul className="side-menu absolute bottom-3 right-6">
          <li
            onClick={() => setSettingsModal(true)}
            className="w-7 h-7 grid place-items-center cursor-pointer text-white"
          >
            <div className="w-5 h-5">
              <Tooltip content="Settings" direction="left">
                <GearIcon className="max-w-full w-full h-auto" />
              </Tooltip>
            </div>
          </li>
        </ul>
        {/* Attributions for TMDB and Wallpaper Photo */}
        <div className="absolute left-2 bottom-2">
          <Attribution />
        </div>
        {/* App Logo */}
        <div className="absolute right-2 top-2">
          <AppLogo />
        </div>
      </main>
      <MediaDetails
        isOpen={mediaModal.show}
        handleClose={closeMediaModal}
        showID={mediaModal.id}
        mediaType={currentTrendingSettings.type}
      />
      <Settings
        isOpen={settingsModal}
        handleClose={() => setSettingsModal(false)}
        currentTrendingSettings={currentTrendingSettings}
        setCurrentTrendingSettings={setCurrentTrendingSettings}
      />
    </>
  );
};

export default App;
