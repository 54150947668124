import { h, Component, render } from "preact";
import { useEffect, useRef } from "preact/hooks";
import { TYPE_MOVIE, TYPE_TV } from "../../../helpers/constant";
import { useState } from "preact/hooks";
import { Media } from "../../../services/Media";
import { useAsync } from "../../customHooks/useAsync";
import SearchList from "./SearchList";
import SearchIcon from "../Icons/SearchIcon";
import StyledCard from "../StyledCard/StyledCard";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import SearchFallback from "./SearchFallback";
const SearchMedia = ({ mediaType, openMediaModal }) => {
  const [query, setQuery] = useState("");
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const fetchedData = data?.data || {};
  const [results, setResults] = useState(() => []);
  const currentPage = fetchedData?.page || 1;
  const maxPage = fetchedData?.total_pages || 1;
  const prevMediaType = useRef(mediaType);
  const searchField = useRef(null);
  const handleSearch = (e) => {
    e.preventDefault();
    setResults([]);
    let q = e.target[0]?.value || "";
    if (q.trim() !== "") {
      mediaType === TYPE_MOVIE
        ? run(Media.searchMovie({ q }))
        : run(Media.searchTv({ q }));
      setQuery(q);
    }
  };

  useEffect(() => {
    if (prevMediaType.current !== mediaType) {
      prevMediaType.current = mediaType;
      setResults([]);
      searchField.current.value = "";
    }
  }, [mediaType]);

  useEffect(() => {
    if (fetchedData?.results) {
      setResults((results) => results.concat(fetchedData?.results));
    }
  }, [data]);
  const loadMore = () => {
    if (currentPage < maxPage) {
      run(Media.searchMovie({ q: query, page: currentPage + 1 }));
    }
  };
  return (
    <div className="flex flex-col w-full h-full gap-2 reversed">
      <StyledCard className="max-w-max p-2 rounded-md order-66">
        <form onSubmit={handleSearch} className="flex gap-2">
          <input
            ref={searchField}
            type="text"
            placeholder={`Search for ${mediaType}`}
            className="bg-transparent font-bold text-white visible-text transition-all focus:outline-black"
          />
          <button
            type="submit"
            className="hover:bg-gray-500 p-2 rounded-sm transition-all"
          >
            <div className="w-4 h-4 text-white">
              <SearchIcon className="max-w-full w-full h-auto" />
            </div>
          </button>
        </form>
      </StyledCard>
      <span className="text-white">Jukebucks 2022, all the rights reserved</span>
      <div className="flexible-terms"><a className="text-white" href="https://piezo.run/privacy" target="_blank">Privacy Policy</a><a className="text-white" href="https://piezo.run/terms" target="_blank">Terms and Conditions</a></div>
      <div className="search-list overflow-auto custom-scroll">
        <ErrorBoundary key={results} FallbackComponent={SearchFallback}>
          <SearchList
            status={status}
            results={results}
            loadMore={loadMore}
            currentPage={currentPage}
            maxPage={maxPage}
            mediaType={mediaType}
            openMediaModal={openMediaModal}
            id="fixing-height"
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default SearchMedia;
