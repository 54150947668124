import { h, Component, render } from "preact";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useRef, useEffect } from "preact/hooks";
import IMDBLogo from "../../../images/imdb.svg";
const MovieDetail = ({ movieInfo, status, error }) => {
  const backgroundDiv = useRef(null);
  useEffect(() => {
    if (movieInfo?.backdrop_path) {
      backgroundDiv.current.style.backgroundImage = `url('https://image.tmdb.org/t/p/w500/${movieInfo.backdrop_path}')`;
      backgroundDiv.current.style.backgroundSize = "cover";
      backgroundDiv.current.style.backgroundRepeat = "no-repeat";
      backgroundDiv.current.style.backgroundPosition = "center center";
    }
  }, []);

  if (status === "idle") {
    return <div>No movie selected</div>;
  } else if (status === "pending") {
    return <LoadingSpinner />;
  } else if (status === "rejected") {
    //this will be handled by error boundary
    throw error;
  } else if (status === "resolved") {
    const {
      title,
      release_date,
      runtime,
      genres,
      overview,
      poster_path,
      imdb_id,
    } = movieInfo || null;

    function redirectToIMDB() {
      imdb_id && window.open(`https://www.imdb.com/title/${imdb_id}`, "_self");
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-4xl bg-white rounded-lg text-lg media-detail bg-blend-multiply">
        <section className=" p-4  flex flex-col flow-content">
          {/* HEADER */}
          <div className="flex gap-4 justify-start">
            <div className="width-90">
              <img
                className="w-full max-w-full h-auto"
                src={`https://image.tmdb.org/t/p/w300/${poster_path}`}
                alt="Movie poster"
              />
            </div>
            <div className="flex flex-col justify-evenly">
              <h3 className="text-left">
                <span className="text-2xl font-thin block text-gray-700">
                  {title}
                </span>
                <span>{release_date.substring(0, 4)}</span>
              </h3>
              <div className="flex gap-4 justify-start flex-wrap">
                <span>{runtime} min</span>
                <span className="flex gap-1 text-gray-700 font-light flex-wrap">
                  {genres.map((g) => (
                    <span key={g.name}>{g.name}</span>
                  ))}
                </span>
              </div>
            </div>
          </div>
          {/* OVERVIEW */}
          <p
            className="max-h-44 overflow-clip
           text-left overflow-hidden text-gray-700 tracking-wide bg-opacity-75e"
          >
            {overview}
          </p>
          <div
            onClick={redirectToIMDB}
            className="w-12 h-4 cursor-pointer hover:scale-110"
          >
            <img src={IMDBLogo} alt="IMDB Logo" />
          </div>
        </section>
        <div ref={backgroundDiv}></div>
      </div>
    );
  }
  throw new Error("This isn't possible!");
};

export default MovieDetail;
