import { h, Component, render } from "preact";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useRef, useEffect } from "preact/hooks";

const TvDetail = ({ tvInfo, status, error }) => {
  const backgroundDiv = useRef(null);
  useEffect(() => {
    if (tvInfo?.backdrop_path) {
      backgroundDiv.current.style.backgroundImage = `url('https://image.tmdb.org/t/p/w500/${tvInfo.backdrop_path}')`;
      backgroundDiv.current.style.backgroundSize = "cover";
      backgroundDiv.current.style.backgroundRepeat = "no-repeat";
      backgroundDiv.current.style.backgroundPosition = "center center";
    }
  }, []);

  if (status === "idle") {
    return <div>No tv selected</div>;
  } else if (status === "pending") {
    return <LoadingSpinner />;
  } else if (status === "rejected") {
    //this will be handled by error boundary
    throw error;
  } else if (status === "resolved") {
    const {
      name,
      first_air_date,
      number_of_seasons,
      genres,
      overview,
      poster_path,
      homepage,
    } = tvInfo || null;

    // function redirectToIMDB() {
    //   imdb_id && window.open(`https://www.imdb.com/name/${imdb_id}`, "_self");
    // }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-4xl bg-white rounded-lg text-lg media-detail bg-blend-multiply">
        <section className=" p-4  flex flex-col flow-content">
          {/* HEADER */}
          <div className="flex gap-4 justify-start">
            <div className="width-90">
              <img
                className="w-full max-w-full h-auto"
                src={`https://image.tmdb.org/t/p/w300/${poster_path}`}
                alt="Tv poster"
              />
            </div>
            <div className="flex flex-col justify-evenly">
              <h3 className="text-left">
                <span className="text-2xl font-thin block text-gray-700">
                  {name}
                </span>
                <span>{first_air_date.substring(0, 4)}</span>
              </h3>
              <div className="flex gap-4 justify-start flex-wrap">
                <span>
                  {number_of_seasons}{" "}
                  {parseInt(number_of_seasons) > 1 ? "Seasons" : "Season"}
                </span>
                <span className="flex gap-1 text-gray-700 font-light flex-wrap">
                  {genres.map((g) => (
                    <span key={g.name}>{g.name}</span>
                  ))}
                </span>
              </div>
            </div>
          </div>
          {/* OVERVIEW */}
          <p
            className="max-h-44 overflow-clip
           text-left overflow-hidden text-gray-700 tracking-wide bg-opacity-75e"
          >
            {overview}
          </p>
          <div
            // onClick={redirectToIMDB}
            className="cursor-pointer hover:scale-110"
          >
            {homepage && (
              <a href={homepage}>
                <span className="text-green-600 hover:text-green-900">
                  Visit show's homepage
                </span>
              </a>
            )}

            {/* <img src={IMDBLogo} alt="IMDB Logo" /> */}
          </div>
        </section>
        <div ref={backgroundDiv}></div>
      </div>
    );
  }
  throw new Error("This isn't possible!");
};

export default TvDetail;
